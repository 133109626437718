import React, { Component } from "react";
import { Table } from "react-bootstrap";

class BrawlerList extends Component {
  render() {
    const brawlers = this.props.sortedBrawlers || [];
    const totalLost = brawlers.reduce((total, b) => total + b.lostTrophies, 0);

    return (
      <Table striped hover id="brawlerList">
        <thead>
          <tr>
            <th>Brawler</th>
            <th>Trophies</th>
            <th>Lost</th>
          </tr>
        </thead>
        <tbody>
          {brawlers.map((brawler) => (
            <tr key={brawler.id}>
              <td>
                <img src={brawler.imageUrl} width="48" alt="" />
                {brawler.name}
              </td>
              <td>{brawler.trophies}</td>
              <td>{brawler.lostTrophies}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th>Total</th>
            <td></td>
            <td>{totalLost}</td>
          </tr>
        </tfoot>
      </Table>
    );
  }
}

export default BrawlerList;
