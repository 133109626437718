import React, { Component } from "react";
import BrawlerList from "./components/BrawlerList";
import PlayerTagForm from "./components/PlayerTagForm";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { player: null, sortedBrawlers: [], updatedAt: undefined };
    this.handlePlayerChange = this.handlePlayerChange.bind(this);
  }

  handlePlayerChange(player, sortedBrawlers) {
    this.setState({ player, sortedBrawlers, updatedAt: new Date() });
  }

  render() {
    return (
      <div>
        <div style={{ background: "#f3efe3" }}>
          <div className="container">
            <div className="row align-items-lg-center">
              <div className="col-8 mx-auto col-md-4 order-md-2 col-lg-5 d-none d-sm-block">
                <img
                  alt="hero"
                  src="undraw_performance_overview_re_mqrq.svg"
                  style={{ width: "600px", height: "400px" }}
                  className="img-fluid mb-5 mt-5"
                />
              </div>
              <div className="col-md-8 order-md-1 col-lg-7 text-center text-md-start mb-5 mt-5">
                <h1 className="mb-3">&#127942; Brawl Stars trophies</h1>
                <p className="lead mb-4">
                  Quickly view your brawlers who are going lost the most
                  trophies, so you can optimize them to preserve your level.
                </p>
                <p>
                  All data come from the official Brawl Stars API and nothing if
                  stored outside your browser.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white py-4 shadow-sm">
          <div className="container">
            <PlayerTagForm onPlayerChange={this.handlePlayerChange} />
          </div>
        </div>

        <div className="container">
          <br />
          {this.state.player && <h2>{this.state.player.name}</h2>}
          {this.state.updatedAt && (
            <small>Last update: {this.state.updatedAt.toLocaleString()}</small>
          )}
          <br />
          {this.state.sortedBrawlers.length > 0 && (
            <small>
              {this.state.sortedBrawlers.length} brawlers to optimize
            </small>
          )}
          <h3 className="mb-3 mt-3">Your brawlers</h3>
          <BrawlerList
            player={this.state.player}
            sortedBrawlers={this.state.sortedBrawlers}
          />
        </div>
      </div>
    );
  }
}

export default App;
