import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";

class BrawlerList extends Component {
  constructor(props) {
    super(props);
    this.refreshInterval = undefined;
    this.token =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiIsImtpZCI6IjI4YTMxOGY3LTAwMDAtYTFlYi03ZmExLTJjNzQzM2M2Y2NhNSJ9.eyJpc3MiOiJzdXBlcmNlbGwiLCJhdWQiOiJzdXBlcmNlbGw6Z2FtZWFwaSIsImp0aSI6IjFlMjM1ODVhLTc2NzYtNGMxMS1hMzI4LWE1ODk0NThiYjAxZiIsImlhdCI6MTY0NTU1ODk3NSwic3ViIjoiZGV2ZWxvcGVyLzQ1OGMzYjYzLTk5YzYtMGY1MC0yOGI1LTg2MDlhNzExOGI3MSIsInNjb3BlcyI6WyJicmF3bHN0YXJzIl0sImxpbWl0cyI6W3sidGllciI6ImRldmVsb3Blci9zaWx2ZXIiLCJ0eXBlIjoidGhyb3R0bGluZyJ9LHsiY2lkcnMiOlsiMTY0LjEzMi4yMjcuMjU0IiwiOTEuMTYxLjkxLjE1NSJdLCJ0eXBlIjoiY2xpZW50In1dfQ.wTTZ9V7LGTs3cBO92mIqQEN0EnlUutKysw-nnBuQg2doJ8NMoIvDiGUc0UPnQEK72dDNAlMpZjvBPSP6q_Mi8w";
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRefreshChange = this.handleRefreshChange.bind(this);
    this.handleEnableNotifChange = this.handleEnableNotifChange.bind(this);

    const playerTag = localStorage.getItem("playerTag") || "20CU98JGR";
    const enableNotif = localStorage.getItem("enableNotif") === "true";
    this.state = { playerTag, enableNotif };
    let previousBrawler = null;
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group className="mb-3" controlId="playerTag">
          <Form.Label>What's your player tag?</Form.Label>
          <Form.Control
            type="text"
            placeholder="XXXXXXXXX (without #)"
            onChange={this.handleChange}
            value={this.state.playerTag}
          />
          <Form.Text className="text-muted">
            You can find it in your profile page under your avatar
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="autorefresh">
          <Form.Check
            type="checkbox"
            label="Refresh every 2 minutes"
            onChange={this.handleRefreshChange}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="enableNotif">
          <Form.Check
            type="checkbox"
            label="Enable notifications (soon)"
            checked={this.state.enableNotif}
            onChange={this.handleEnableNotifChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Search
        </Button>
      </Form>
    );
  }

  handleChange(e) {
    this.setState({ playerTag: e.target.value });
    localStorage.setItem("playerTag", e.target.value);
  }

  handleEnableNotifChange(e) {
    const checked = e.target.checked;
    this.setState({ enableNotif: checked });
    localStorage.setItem("enableNotif", checked);

    if (checked) {
      if (!("Notification" in window)) {
        alert(`Your browser doesn't support notifications`);
      } else if (Notification.permission === "granted") {
        new Notification(`Notifications enabled`);
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            new Notification(`Notifications enabled`);
          }
        });
      }
    }
  }

  handleRefreshChange(e) {
    const checked = e.target.checked;
    if (checked) {
      this.refreshInterval = setInterval(() => {
        this.setState({ playerTag: this.state.playerTag });
        this.handleSubmit();
      }, 2 * 60 * 1000);
    } else if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  handleSubmit(e) {
    e?.preventDefault();
    if (!this.state.playerTag.length) {
      return;
    }

    fetch(`/v1/players/%23${this.state.playerTag}`, {
      method: "GET",
      headers: new Headers({ Authorization: `Bearer ${this.token}` }),
    })
      .then((res) => res.json())
      .then((data) => {
        const sortedBrawlers = (data?.brawlers || [])
          .filter((b) => b.trophies > 500)
          .map((b) => {
            const slug = b.name
              .toLowerCase()
              .split(" ")
              .map((w) => w[0].toUpperCase() + w.substring(1))
              .join("-")
              .replace(".-", ".")
              .replace("8-bit", "8-Bit");
            return {
              ...b,
              lostTrophies: b.trophies % 25,
              imageUrl: `https://cdn.brawlify.com/brawler/${slug}.png`,
            };
          })
          .filter((b) => b.lostTrophies > 0)
          .sort((a, b) => b.lostTrophies - a.lostTrophies);
        this.props.onPlayerChange(data, sortedBrawlers);

        if (this.state.enableNotif && sortedBrawlers.length) {
          const b = sortedBrawlers[0];
          if (!this.previousBrawler || this.previousBrawler.name !== b.name) {
            this.previousBrawler = b;
            new Notification(
              `Next brawler: ${b.name} (${b.trophies} | ${b.lostTrophies})`,
              { icon: b.imageUrl }
            );
          }
        }
      });
  }
}

export default BrawlerList;
